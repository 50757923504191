<script setup lang="ts">
import RoleSelectorDropdown from '@/components/common/molecules/RoleSelectorDropdown.vue';
import { computed, ref } from 'vue';
import { showError, showMessage } from '@/utils/alert';
import { useIsWorkspaceAdmin } from '@/store/hooks';
import { useCurrentWorkspace } from '@/auth/use-workspace';
import {
  useInviteMembersSuggested,
  useSuggestInviteMembers,
} from '@/api/users';
import { useInviteWorkspaceMembers } from '@/api/accounts';
import {
  validationEmailsInUse,
  validationNotRequiredEmail,
} from '@/utils/validation/text-field';
import { OnboardingStepEnum } from '@/utils/onboarding';
import { AccountRole } from '@/api/types';
import { onboardingMap } from '@/components/onboarding/onboarding.config';
import { IMAGES } from '@/constants/assets';

type MemberInput = {
  id: string;
  value: string;
  valid: boolean;
  isLoading: boolean;
  sent: boolean;
};

const isAdmin = useIsWorkspaceAdmin();
const { data: workspace, refetch } = useCurrentWorkspace();
const { data: suggestInviteMembers } = useSuggestInviteMembers();
const { mutateAsync: onInviteMembers } = useInviteWorkspaceMembers({
  onSuccess: () => onInviteSent(),
  onError: (error: Error) => showError(error),
});
const { mutateAsync: onSetSuggested } = useInviteMembersSuggested();

const currentInputMemberId = ref('');
const memberInputs = ref<MemberInput[]>([
  {
    id: '1',
    value: '',
    valid: false,
    isLoading: false,
    sent: false,
  },
  {
    id: '2',
    value: '',
    valid: false,
    isLoading: false,
    sent: false,
  },
  {
    id: '3',
    value: '',
    valid: false,
    isLoading: false,
    sent: false,
  },
]);
const role = ref<AccountRole>(AccountRole.LITE);

const existingEmails = computed(() =>
  workspace.value?.accounts.map(({ person }) => person.email),
);

const skipClickHandler = async () => {
  await onSetSuggested(true);
};

const copyLink = () => {
  // ToDo: use correct link value, when BE will be ready
  const link = '';
  navigator.clipboard.writeText(link);
  showMessage('Sharing link copied to clipboard');
};

// 'isValid' from <v-form v-slot={ isValid }> loses reactivity, so to toggle disable 'send' button, this workaround is needed
const setValidForm = (member: MemberInput, isValid: boolean) => {
  member.valid = isValid;
};

const handleInvite = async (member: MemberInput) => {
  currentInputMemberId.value = member.id;
  member.isLoading = true;
  await onInviteMembers({
    workspaceId: workspace.value?.id as string,
    invites: [
      {
        email: member.value,
        role: role.value,
      },
    ],
  });
  member.isLoading = false;
};

const onInviteSent = () => {
  const member = memberInputs.value?.find(
    ({ id }) => id === currentInputMemberId.value,
  );
  if (member) {
    member.sent = true;
  }
  refetch();
};
</script>

<template>
  <v-dialog width="900" :model-value="suggestInviteMembers" persistent>
    <v-row class="invite-member-popup m-0 w-full rounded-[28px] bg-white">
      <v-col cols="6" class="flex flex-col gap-y-5 w-full py-10 px-10">
        <div class="flex flex-col gap-y-2">
          <h2
            class="text-black font-gilroy text-[24px] leading-[36px] font-semibold"
          >
            {{ onboardingMap[OnboardingStepEnum.INVITE].title }}
          </h2>
          <div class="typo-body1 text-grey-100">
            {{ onboardingMap[OnboardingStepEnum.INVITE].description }}
          </div>
        </div>
        <div class="flex flex-col gap-y-3">
          <div class="flex flex-col gap-y-2">
            <h5 class="text-black typo-body1">Select team member role</h5>
            <RoleSelectorDropdown
              :role="role"
              :disabled="!isAdmin"
              class="border border-grey-20 rounded h-[38px]"
              hide-desc
              @update:role="role = $event"
            />
          </div>
          <div class="flex flex-col gap-y-2">
            <h5 class="text-black typo-body1">Share via email</h5>
            <div
              v-for="member in memberInputs"
              :key="member.id"
              class="invite-member-popup__input-wrapper flex align-center gap-x-[10px]"
            >
              <v-form
                class="w-full"
                validate-on="lazy"
                @update:model-value="setValidForm(member, $event)"
              >
                <v-text-field
                  id="invite-member-popup__member"
                  ref="membersInputEl"
                  v-model="member.value"
                  :rules="[
                    validationNotRequiredEmail,
                    validationEmailsInUse(
                      existingEmails as string[],
                      member.value,
                    ),
                  ]"
                  :loading="member.isLoading"
                  :disabled="member.isLoading || member.sent"
                  class="invite-member-popup__input grow"
                  hide-details="auto"
                  placeholder="Invite your teammates"
                  validate-on="lazy"
                />
                <v-btn
                  class="invite-member-popup__input-btn"
                  color="primary"
                  height="28"
                  :variant="member.sent ? 'text' : 'flat'"
                  :disabled="!member.valid || !member.value"
                  :loading="member.isLoading"
                  @click="handleInvite(member)"
                >
                  {{ member.sent ? 'Invited' : 'Send' }}
                </v-btn>
              </v-form>
            </div>
          </div>
          <!--      ToDo: remove 'false', when BE will be ready -->
          <div v-if="false" class="flex flex-col gap-y-2">
            <h5 class="typo-body1">Invite with link</h5>
            <div class="flex gap-x-[10px]">
              <v-text-field
                id="invite-link"
                :model-value="'https://noty.com/file/d/1Ivsdb'"
                class="grow"
                hide-details="auto"
                disabled
                readonly
              />
              <v-btn
                variant="outlined"
                color="primary"
                height="34"
                :ripple="{ class: 'text-primary' }"
                @click="copyLink"
              >
                Copy link
              </v-btn>
            </div>
            <div class="typo-body1 text-grey-100">
              Anyone with access to this link can join your team
            </div>
          </div>
        </div>
        <div class="flex justify-space-between">
          <v-btn
            variant="text"
            text="Skip"
            class="bg-white text-primary px-4 py-2"
            @click="skipClickHandler"
          />
        </div>
      </v-col>
      <v-col
        cols="6"
        class="flex align-center justify-center w-full bg-gradient-purple rounded-r-[28px] pr-0 pl-10 m-0 py-10"
      >
        <img
          :src="IMAGES.INVITE_MEMBER"
          width="256"
          height="256"
          class="object-contain"
          alt=""
        />
      </v-col>
    </v-row>
  </v-dialog>
</template>

<style lang="scss">
:deep(.v-selection-control-group) {
  @apply gap-3;
}

.custom-radio {
  @apply gap-1 items-start;

  :deep(.v-selection-control__wrapper) {
    @apply w-[24px] h-[24px];
  }

  :deep(.v-label) {
    @apply max-w-[386px] w-full opacity-100;
  }

  :deep(.v-selection-control__input .v-icon) {
    @apply text-primary opacity-100;
  }
}

.invite-member-popup {
  &__input-wrapper {
    position: relative;
  }

  &__input {
    .v-field__field {
      padding: 8px;
    }

    input {
      max-width: 279px;
      min-height: 22px !important;
      padding: 0 !important;
      text-overflow: ellipsis;
    }
  }

  &__input-btn {
    position: absolute;
    top: 6px;
    right: 8px;
  }
}
</style>
