<script setup lang="ts">
import {
  MEMBER_ROLES_OPTIONS,
  MemberOption,
} from '@/constants/dropdownOptions';
import { ChevronDownIcon, CheckIcon } from 'vue-tabler-icons';
import { AccountRole, SpaceRole } from '@/api/types';
import { reactive } from 'vue';

const props = withDefaults(
  defineProps<{
    role: AccountRole | SpaceRole;
    titleProp?: string;
    hideDesc?: boolean;
    rolesSet?: MemberOption[];
    disabled?: boolean;
  }>(),
  {
    titleProp: 'name', // property used to add inner data to the dropdown
    rolesSet: () => MEMBER_ROLES_OPTIONS,
  },
);

const emit = defineEmits<{
  (e: 'update:role', newRole: AccountRole): void;
}>();

const updateRole = (newRole: AccountRole) => {
  emit('update:role', newRole);
};

const state = reactive({
  role: props.role,
});
</script>

<template>
  <v-select
    v-model="state.role"
    :item-title="titleProp"
    :items="rolesSet"
    variant="outlined"
    :menu-icon="!props.disabled && ChevronDownIcon"
    :menu-props="{ offset: 10 }"
    :disabled="props.disabled"
    class="role-selector text-black"
    @update:model-value="updateRole"
  >
    <template #selection="{ item }">
      <div class="text-slate typo-body1 text-sm font-inter font-medium">
        {{ item.title }}
      </div>
    </template>
    <template #item="{ props: prop, item }">
      <v-list-item
        v-bind="prop"
        :class="[
          'role-selector__item font-inter pb-2',
          { 'role-selector__item-active': state.role === item.raw.value },
        ]"
        :append-icon="
          state.role === item.raw.value && !hideDesc ? CheckIcon : ''
        "
      >
        <span v-if="!hideDesc" class="text-xs text-grey-70">
          {{ item.raw.description }}
        </span>
      </v-list-item>
    </template>
  </v-select>
</template>

<style scoped>
.role-selector {
  min-width: 115px;
}

.role-selector :deep(.v-field__outline) .v-field__outline__start,
.role-selector :deep(.v-field__outline) .v-field__outline__end {
  @apply border-0;
}

.role-selector :deep(.v-input__details) {
  @apply absolute;
}

.role-selector :deep(.v-field__field) {
  @apply w-16;
}

.role-selector :deep(.v-field--appended) {
  @apply pe-2;
}

.role-selector :deep(.v-field__input) {
  @apply ps-2;
}

.role-selector :deep(.v-select__menu-icon) {
  @apply text-grey-90;
}

.role-selector :deep(.v-select__menu-icon) .icon-tabler-chevron-down {
  width: 16px;
  height: 16px;
}

.role-selector__item {
  width: 384px;
  padding: 4px 56px 4px 16px !important;
}

.role-selector__item-active {
  padding: 4px 24px 4px 16px !important;
}
</style>
