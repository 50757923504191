<script lang="ts" setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';

const canvas = ref<HTMLCanvasElement | null>(null);
const particles: ConfettiParticle[] = [];
const maxConfetti = 150;

let W = window.innerWidth;
let H = window.innerHeight;
let context: CanvasRenderingContext2D | null = null;

const randomFromTo = (from: number, to: number) =>
  Math.random() * (to - from) + from;

class ConfettiParticle {
  x: number;
  y: number;
  width: number;
  height: number;
  color: string;
  velocityX: number;
  velocityY: number;
  opacity: number;
  gravity: number;
  decay: number;
  angle: number;
  angularVelocity: number;

  constructor() {
    const possibleColors = [
      'DodgerBlue',
      'OliveDrab',
      'Gold',
      'Pink',
      'SlateBlue',
      'LightBlue',
      'Gold',
      'Violet',
      'PaleGreen',
      'SteelBlue',
      'SandyBrown',
      'Chocolate',
      'Crimson',
    ];

    this.x = W / 2;
    this.y = H / 2;
    this.width = randomFromTo(0.5, 1);
    this.height = randomFromTo(1, 2);
    this.color =
      possibleColors[Math.floor(Math.random() * possibleColors.length)];
    this.velocityX = randomFromTo(-10, 10);
    this.velocityY = randomFromTo(-5, -20);
    this.opacity = 1;
    this.gravity = 0.4;
    this.decay = randomFromTo(0.001, 0.01);
    this.angle = randomFromTo(0, 2 * Math.PI);
    this.angularVelocity = randomFromTo(-0.1, 0.1);
  }

  draw() {
    if (!context) {
      return;
    }
    context.save();
    context.globalAlpha = this.opacity;
    context.translate(this.x, this.y);
    context.rotate(this.angle);
    context.fillStyle = this.color;
    context.fillRect(
      -this.width / 2,
      -this.height / 2,
      this.width,
      this.height,
    );
    context.restore();
  }

  update() {
    this.x += this.velocityX;
    this.y += this.velocityY;

    if (this.velocityY < 0) {
      this.width += 0.2;
      this.height += 0.4;
    }

    this.velocityY += this.gravity;
    this.opacity -= this.decay;
    this.angle += this.angularVelocity;
  }

  isDead() {
    return this.opacity <= 0;
  }
}

const Draw = () => {
  if (!context) {
    return;
  }

  requestAnimationFrame(Draw);
  context.clearRect(0, 0, W, H);

  for (let i = particles.length - 1; i >= 0; i--) {
    const particle = particles[i];
    particle.update();
    particle.draw();

    if (particle.isDead()) {
      particles.splice(i, 1);
    }
  }
};

const handleResize = () => {
  W = window.innerWidth;
  H = window.innerHeight;

  if (canvas.value) {
    canvas.value.width = W;
    canvas.value.height = H;
  }
};

onMounted(() => {
  if (canvas.value) {
    canvas.value.width = W;
    canvas.value.height = H;
    context = canvas.value.getContext('2d');
  }

  for (let i = 0; i < maxConfetti; i++) {
    particles.push(new ConfettiParticle());
  }

  Draw();
  window.addEventListener('resize', handleResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<template>
  <canvas ref="canvas" />
</template>

<style lang="scss" scoped>
canvas {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
}
</style>
