import { useUser } from '@/auth/use-session';
import deepmerge from 'deepmerge';
import { store } from '@/store';
import { showError } from '@/utils/alert';
import { OnboardingStatus, OnboardingType } from '@/api/types';
import {
  updateUserData,
  updateUserMetadata,
  useUpdateUserData,
} from '@/api/users';
import { computed, unref, type Ref } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { ZoomJoinToType } from '@/api/types';
import type { UpdateUserMetadata } from 'trpc_backend/src/domain/users/users.types';

export enum OnboardingStepEnum {
  USAGE = 'usage',
  GOAL = 'goal',
  WORKSPACE = 'workspace',
  CARD = 'card',
  EXTENSION = 'extension',
  DONE = 'done',
  SETTINGS = 'settings',
  INVITE = 'invite',
}

export const useIsOnboardingProcess = () => {
  const { data } = useUser();
  return computed(() => {
    if (!data.value) {
      return false;
    }
    return ![OnboardingStatus.Done, OnboardingStatus.Extension].includes(
      data.value?.onboardingStatus,
    );
  });
};

/** @deprecated use useSetOnboarding instead   */
export const setOnboarding = async (
  onboarding: OnboardingType,
  immediate = true,
) => {
  if (!store.user) {
    return;
  }
  try {
    const onboardingResponse = await updateUserData({
      onboarding: deepmerge(
        store.user.onboarding as OnboardingType,
        onboarding,
      ),
    });
    if (immediate) {
      store.user.onboarding = onboardingResponse.onboarding as OnboardingType;
    }
  } catch (e) {
    showError(e as Error);
  }
};

export const useSetOnboarding = () => {
  const { data } = useUser();

  const queryClient = useQueryClient();

  const { mutate, mutateAsync, isLoading, isSuccess } = useMutation({
    mutationKey: ['user'],
    mutationFn: (updatedKey: OnboardingType) =>
      updateUserData({
        onboarding: { ...(data.value?.onboarding as object), ...updatedKey },
      }),
    onSuccess(data) {
      queryClient.setQueryData(['user'], () => data);
    },
    onError(error: Error) {
      showError(error);
    },
  });
  return { mutate, mutateAsync, isLoading, isSuccess };
};

export const useSetUserOnboarding = (nextStep: MaybeRef<OnboardingStatus>) => {
  const { mutate: setOnboardingStatus, isLoading: userLoading } =
    useUpdateUserData();

  const {
    mutate,
    mutateAsync,
    isLoading: metaLoading,
    isSuccess,
  } = useMutation({
    mutationKey: ['setUserOnboarding'],
    mutationFn: (data: UpdateUserMetadata) => updateUserMetadata(data),
    onSuccess() {
      setOnboardingStatus({ onboardingStatus: unref(nextStep) });
    },
    onError(error: Error) {
      showError(error);
    },
  });
  return {
    mutate,
    mutateAsync,
    isLoading: metaLoading && userLoading,
    isSuccess,
  };
};

export const getProgress = (step: number) => {
  return Math.round((2 * sigmoid(step) - 1) * 100);
};

function sigmoid(x: number, factor = 2) {
  return 1 / (1 + Math.exp(-x / factor));
}

export const useDefaultWorkspaceName = () => {
  const { data: user } = useUser();
  return computed(() => {
    if (user.value) {
      const userName =
        user.value.name?.split(' ')[0] || user.value.email.split('@')[0];
      return `${userName.slice(0, 21)}'s workspace`;
    }
    return 'My workspace';
  });
};

export const isZoomEnabled = () => {
  return computed(() =>
    [
      ZoomJoinToType.ALL,
      ZoomJoinToType.EXTERNAL,
      ZoomJoinToType.INTERNAL,
      ZoomJoinToType.OWNED,
    ].includes(
      store.currentWorkspace?.accounts?.find(
        ({ userId }) => userId === store.user?.id,
      )?.zoomBotJoiningStrategy as ZoomJoinToType,
    ),
  );
};

export const typeWriterEffect = (inputText: string, outputRef: Ref<string>) => {
  let index = 0;
  outputRef.value = '';
  const animateText = () => {
    if (index < inputText.length) {
      outputRef.value += inputText[index++];
      requestAnimationFrame(animateText);
    }
  };
  requestAnimationFrame(animateText);
};
