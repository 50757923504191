import { computed } from 'vue';
import { api } from '@/api';
import {
  OAuth2Status,
  type OAuth2Provider,
  type UserGetOAuth2Token,
  type UserUpdateData,
} from '@/api/types';
import { isStringOrError } from '@/utils';
import { showError } from '@/utils/alert';
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import { useBroadcastChannel } from '@vueuse/core';
import { useCurrentWorkspaceId } from '@/auth/use-workspace';
import type { UpdateUserMetadata } from 'trpc_backend/src/domain/users/users.types';

export const updateUserData = (userProperties: UserUpdateData) => {
  return api.users.updatePrincipalData.mutate(userProperties);
};

export const updateUserMetadata = (userMetadata: UpdateUserMetadata) => {
  return api.users.updateMetadata.mutate(userMetadata);
};

export const useOAuth2ProviderToken = (provider: OAuth2Provider) => {
  const { post } = useBroadcastChannel({ name: `${provider} OAuth2` });

  return useMutation({
    mutationFn: async (
      dto: Pick<UserGetOAuth2Token, 'code' | 'redirectUrl'>,
    ) => {
      try {
        await api.users.credentials.handleOAuthCode.query({ ...dto, provider });
        const result = OAuth2Status.Authorized;
        post(result);
        return result;
      } catch (error) {
        if (isStringOrError(error)) {
          showError(error);
        }

        const result = OAuth2Status.Failed;
        post(result);
        return result;
      }
    },
  });
};

export const setPrimaryAccount = (accountId: string) => {
  return api.users.setPrimaryAccount.mutate({ accountId });
};

export const invite = (emails: string[]) => {
  return api.users.invite.mutate({ emails });
};

export const useUpdateUserData = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: UserUpdateData) =>
      api.users.updatePrincipalData.mutate(data),
    onSuccess: () => queryClient.invalidateQueries(['user']),
  });
};

export const useSuggestInviteMembers = () => {
  const workspaceId = useCurrentWorkspaceId();

  return useQuery({
    enabled: computed(() => !!workspaceId.value),
    queryKey: computed(() => ['suggestInviteMembers', workspaceId.value]),
    queryFn: () =>
      api.users.engagement.suggestInviteMembers.query({
        workspaceId: workspaceId.value,
      }),
  });
};

export const useInviteMembersSuggested = () => {
  const queryClient = useQueryClient();
  const workspaceId = useCurrentWorkspaceId();

  const queryKey = ['suggestInviteMembers', workspaceId.value];

  return useMutation({
    mutationFn: (suggested: boolean) =>
      api.users.engagement.inviteMembersSuggested.mutate({
        suggested,
        workspaceId: workspaceId.value,
      }),
    onMutate: async (suggested) => {
      await queryClient.cancelQueries([
        'suggestInviteMembers',
        workspaceId.value,
      ]);

      const prevSuggestedValue = queryClient.getQueryData<boolean>(queryKey);
      queryClient.setQueryData(queryKey, !suggested);

      return { prevSuggestedValue };
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData(queryKey, context?.prevSuggestedValue);
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });
};
