import { isEmail, isString } from '@/utils';

export const validationRequired = (value: string | string[]): true | string =>
  (isString(value) ? !!value.trim().length : !!value.length) ||
  'Required field';

export const validationEmail = (value: string | string[]): true | string =>
  (isString(value)
    ? isEmail(value)
    : !value.some((email): boolean => !isEmail(email))) ||
  'You have an invalid email value';

export const validationNotRequiredEmail = (
  value: string | string[],
): boolean | string => {
  if (isString(value) ? !value : !value?.length) {
    return true;
  }
  return validationEmail(value);
};

export const validationMinLength = (
  value: string,
  minChars = 2,
): true | string =>
  value.trim().length >= minChars ||
  `Please use at least ${minChars} char${minChars === 1 ? '' : 's'}`;

export const validationMaxLength = (
  value: string | string[],
  maxLength = 5,
): true | string =>
  value.length <= maxLength || 'You have reached maximum value';

export const validationEmailsInUse = (
  existingEmails: string[],
  emails: string | string[],
): boolean | string => {
  const data = isString(emails) ? [emails] : emails;
  const repeatedEmail: string | undefined = data.find((email) =>
    existingEmails?.includes(email),
  );
  return (
    repeatedEmail === undefined || `The ${repeatedEmail} has been already used`
  );
};
