<script setup lang="ts">
import MenuIcon from '@/components/common/molecules/MenuIcon.vue';
import { computed, ref, watch } from 'vue';
import { useUser } from '@/auth/use-session';
import { useGmeetLang } from '@/components/SidebarMenu/hooks';
import { useIsWorkspaceAdmin } from '@/store/hooks';
import { useCurrentWorkspace } from '@/auth/use-workspace';
import { useManageMeetingRewinds } from '@/components/settings/Workspace/hooks';
import { onboardingMap } from '@/components/onboarding/onboarding.config';
import { IMAGES } from '@/constants/assets';
import { CONVERSATION_LANGUAGES } from '@/constants/conversationLanguages';
import { OnboardingStepEnum } from '@/utils/onboarding';
import { OnboardingStatus } from '@/api/types';

const isActive = ref(false);

const isAdmin = useIsWorkspaceAdmin();
const { data: user, isFetched } = useUser();
const { isFetched: isWorkspacesFetched } = useCurrentWorkspace();
const { langCode, updateLang, isUpdating } = useGmeetLang();
const { isSaving, meetingRewindSetting, save } = useManageMeetingRewinds();

const isLoading = computed(() => isUpdating.value || isSaving.value);

const skipClickHandler = () => {
  localStorage.removeItem('showSettingsPopup');
  isActive.value = false;
};

const setSettings = async () => {
  if (!langCode.value) {
    return;
  }
  const requests = [updateLang(langCode.value)];
  if (isAdmin.value) {
    requests.push(save());
  }
  await Promise.all(requests);
  skipClickHandler();
};

watch(
  user,
  (user) => {
    if (user) {
      const showPopup = localStorage.getItem('showSettingsPopup');
      isActive.value =
        user?.onboardingStatus === OnboardingStatus.Done && Boolean(showPopup);
    }
  },
  { immediate: true },
);
</script>

<template>
  <v-dialog
    v-if="isFetched && isWorkspacesFetched"
    width="900"
    :model-value="isActive"
    persistent
  >
    <v-row class="m-0 w-full rounded-[28px] bg-white">
      <v-col
        cols="6"
        :class="`flex flex-col gap-y-6 w-full px-10 ${isAdmin ? 'py-10' : 'justify-center py-0'}`"
      >
        <h2
          class="text-black font-gilroy text-[24px] leading-[36px] font-semibold pb-6 border-b border-grey-20"
        >
          {{ onboardingMap[OnboardingStepEnum.SETTINGS].title }}
        </h2>
        <div class="flex flex-col gap-y-4">
          <h5 class="text-black typo-h5">
            Which language do you use the most?
          </h5>

          <div
            class="bg-white border border-solid border-grey-20 rounded p-2 w-full"
          >
            <v-autocomplete
              v-model="langCode"
              placeholder="Select a language"
              hide-details
              :menu-icon="MenuIcon"
              class="gmeet-langs-autocomplete"
              :items="CONVERSATION_LANGUAGES"
              item-title="name"
              :loading="isUpdating"
              :disabled="isUpdating"
              :menu-props="{ offset: 10 }"
            />
          </div>
        </div>
        <div v-if="isAdmin" class="flex flex-col gap-y-4">
          <h5 class="text-black typo-h5">Send meeting rewinds to</h5>
          <v-radio-group
            v-model="meetingRewindSetting"
            class="rewinds-radio-group"
            hide-details
          >
            <v-radio
              v-for="option in onboardingMap[OnboardingStepEnum.SETTINGS]
                .options"
              :key="option.id"
              :value="option.id"
              class="rewind-radio"
              density="compact"
            >
              <template #label>
                <div class="flex flex-col gap-2 w-full">
                  <div class="typo-body1 text-black">{{ option.label }}</div>
                </div>
              </template>
            </v-radio>
          </v-radio-group>
        </div>
        <v-btn
          text="Continue"
          class="w-full"
          color="primary"
          :disabled="!langCode || isLoading"
          @click="setSettings"
        />
        <v-btn
          variant="text"
          text="You can change this later in Settings"
          class="bg-white text-grey-100 px-4 py-2"
          :loading="isLoading"
          @click="skipClickHandler"
        />
      </v-col>
      <v-col
        cols="6"
        :class="`w-full bg-gradient-blue rounded-r-[28px] pr-0 pl-10 m-0 ${isAdmin ? 'py-10' : 'py-0'}`"
      >
        <img
          :src="IMAGES.SETTINGS"
          :class="`object-contain w-full h-auto ${!isAdmin && 'rounded-r-[28px]'}`"
          alt=""
        />
      </v-col>
    </v-row>
  </v-dialog>
</template>

<style lang="scss" scoped>
.rewinds-radio-group {
  :deep(.v-selection-control-group) {
    @apply gap-3;
  }
}

.rewind-radio {
  @apply gap-1 items-start;

  .v-selection-control__wrapper {
    @apply w-[24px] h-[24px];
  }

  :deep(.v-label) {
    @apply max-w-[386px] w-full opacity-100;
  }

  :deep(.v-selection-control__input .v-icon) {
    @apply text-primary opacity-100;
  }
}
</style>
