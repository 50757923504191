export const DEMO_VIDEO_URL =
  'https://youtu.be/A3UYPtiFguY?si=GdlUlaYAv8TRLZqY';

export const PRICING_PAGE_URL = 'https://noty.ai/pricing';

export const CALENDLY_URL =
  'https://calendly.com/natalie-noty/democall_30minorless';

export const TERMS_OF_SERVICE_URL = 'https://noty.ai/terms-of-service';

export const PRIVACY_POLICY_URL = 'https://noty.ai/privacy-policy';

export const ZAPIER_INTEGRATION_URL =
  'https://zapier.com/apps/notyai/integrations';

export const IMAGES = {
  EMPTY_WORKFLOW:
    'https://storage.googleapis.com/noty-ai/webapp/empty-view/empty-workflow.webp',
  NOTHING_YET:
    'https://storage.googleapis.com/noty-ai/webapp/empty-view/nothing-yet.webp',
  GOOGLE: 'https://storage.googleapis.com/noty-ai/webapp/images/google.webp',
  FORBES: 'https://storage.googleapis.com/noty-ai/webapp/images/forbes.webp',
  TECH_CRUNCH:
    'https://storage.googleapis.com/noty-ai/webapp/images/techcrunch.webp',
  ZAPIER:
    'https://storage.googleapis.com/noty-ai/webapp/images/zapier-logo.webp',
  ONBOARDING:
    'https://storage.googleapis.com/noty-ai/webapp/images/onboarding/onboarding.webp',
  SETTINGS:
    'https://storage.googleapis.com/noty-ai/webapp/images/onboarding/settings.webp',
  MEDAL:
    'https://storage.googleapis.com/noty-ai/webapp/images/onboarding/medal.webp',
  FIRE: 'https://storage.googleapis.com/noty-ai/webapp/images/onboarding/fire.webp',
  MEDITATION:
    'https://storage.googleapis.com/noty-ai/webapp/images/onboarding/meditation.webp',
  CUP: 'https://storage.googleapis.com/noty-ai/webapp/images/onboarding/cup.webp',
  CLOCK:
    'https://storage.googleapis.com/noty-ai/webapp/images/onboarding/clock.webp',
  GLASSES:
    'https://storage.googleapis.com/noty-ai/webapp/images/onboarding/glasses.webp',
  BAG: 'https://storage.googleapis.com/noty-ai/webapp/images/onboarding/bag.webp',
  RUNNER:
    'https://storage.googleapis.com/noty-ai/webapp/images/onboarding/runner.webp',
  LAPTOP:
    'https://storage.googleapis.com/noty-ai/webapp/images/onboarding/laptop.webp',
  CHECK:
    'https://storage.googleapis.com/noty-ai/webapp/images/onboarding/check.webp',
  HI: 'https://storage.googleapis.com/noty-ai/webapp/images/onboarding/hi.webp',
  INVITE_MEMBER:
    'https://storage.googleapis.com/noty-ai/webapp/images/onboarding/invite-member.webp',
};
