<script setup lang="ts">
import Confetti from '@/components/onboarding/Confetti.vue';
import { ref } from 'vue';
import { IMAGES } from '@/constants/assets';
import { onboardingMap } from '@/components/onboarding/onboarding.config';
import { OnboardingStepEnum } from '@/utils/onboarding';

const showModal = ref(true);
</script>

<template>
  <v-dialog :model-value="showModal" persistent fullscreen>
    <div
      class="done-step-popup w-[400px] m-auto flex flex-col align-center gap-y-4 p-[40px] rounded-[28px] bg-white"
    >
      <img :src="IMAGES.HI" width="48" height="48" alt="" />
      <h2 class="font-geologica text-[32px] leading-[48px] font-bold">
        {{ onboardingMap[OnboardingStepEnum.DONE].title }}
      </h2>
    </div>
    <Confetti />
  </v-dialog>
</template>

<style scoped>
.done-step-popup {
  z-index: 2;
}
</style>
